import {
  NavDrawer,
  NavDrawerMenu,
  NavDrawerMenuItem,
} from "@kablamooss/geo-ds-core-components";
import Image from "next/image";
import styled, { css } from "styled-components";
import { useHasPrivilege } from "../../../hooks/useHasPrivilege";
import NavLink from "../../util/NavLink/NavLink";

const StyledNavDrawer = styled(NavDrawer)`
  .env-sandbox & {
    background-color: ${(p) => p.theme.colors.warning.textHover};
  }
`;

interface StyledNavDrawerMenuItemProps {
  isActive: boolean;
}

const StyledNavDrawerMenuItem = styled(
  NavDrawerMenuItem,
)<StyledNavDrawerMenuItemProps>`
  .env-sandbox & {
    ${(p) =>
      !p.isActive &&
      css`
        &:not(:hover) {
          background-color: ${p.theme.colors.warning.textHover};
        }
      `}
  }
`;

const NavDrawerFooter = styled.div`
  display: grid;
  gap: 0.5rem;
  margin-top: auto;
`;

const StyledNSWFSLogo = styled.div`
  display: flex;
  gap: 0.25rem;
  align-items: center;
  ${(p) => p.theme.typography.variants.footnote}
  color: ${(p) => p.theme.colors.neutrals.textInverseWeak};
`;

const StyledGovLogo = styled.div`
  display: flex;
  align-items: end;
  justify-content: space-between;
  ${(p) => p.theme.typography.variants.caption}
  color: ${(p) => p.theme.colors.neutrals.textInverse};
`;

const StyledLogos = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0 0 0.5rem;
  gap: 0.25rem;
`;

const AppNavDrawer = () => {
  const { data: hasCreateBfmcsPrivilege } = useHasPrivilege({
    requestedPermissions: ["create-bfmcs"],
  });

  return (
    <StyledNavDrawer data-testid="nav-drawer" title="Risk Modelling Platform">
      <NavDrawerMenu>
        <NavLink
          exact
          href="/statewide"
          legacyBehavior
          render={({ isActive }) => (
            <StyledNavDrawerMenuItem isActive={isActive}>
              Statewide projects
            </StyledNavDrawerMenuItem>
          )}
        />
        <NavLink
          exact
          href="/"
          legacyBehavior
          render={({ isActive }) => (
            <StyledNavDrawerMenuItem isActive={isActive}>
              BFRMP projects
            </StyledNavDrawerMenuItem>
          )}
        />
        <NavLink
          exact
          href="/local"
          legacyBehavior
          render={({ isActive }) => (
            <StyledNavDrawerMenuItem isActive={isActive}>
              Local projects
            </StyledNavDrawerMenuItem>
          )}
        />
      </NavDrawerMenu>
      <NavDrawerFooter>
        <NavDrawerMenu>
          <NavLink
            href={
              hasCreateBfmcsPrivilege
                ? "/settings/jurisdictions/bfmcs"
                : "/settings/account/notifications"
            }
            legacyBehavior
            render={({ isActive }) => (
              <StyledNavDrawerMenuItem isActive={isActive}>
                Settings
              </StyledNavDrawerMenuItem>
            )}
          />
          <NavLink
            exact
            href="https://rfs.atlassian.net/wiki/spaces/NR/pages/355631188/Risk+Modelling+Platform+RMP+documentation"
            render={({ isActive }) => (
              <StyledNavDrawerMenuItem isActive={isActive}>
                Help
              </StyledNavDrawerMenuItem>
            )}
            target="_blank"
          />
        </NavDrawerMenu>
        <StyledLogos>
          <StyledNSWFSLogo>
            <Image
              alt="NSW RFS logo"
              height="40"
              src="/logos/NSW-RFS-logo.svg"
              width="40"
            />
            NSW RURAL FIRE SERVICE
          </StyledNSWFSLogo>
          <StyledGovLogo>
            <Image
              alt="NSW Government logo"
              height="24"
              src="/logos/NSW-logo.svg"
              width="24"
            />
          </StyledGovLogo>
        </StyledLogos>
      </NavDrawerFooter>
    </StyledNavDrawer>
  );
};

export default AppNavDrawer;
